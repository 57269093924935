import React from "react"

// Component imports
import Layout from "../components/Layout"
import Card from '../components/Card';
import SEO from '../components/SEO';

// Image imports
import mike from '../images/mike-martin-headshot.jpg';

// Routes for URLs
// import * as ROUTES from '../constants/routes';

const Estate = () => {
  return (
    <Layout>
        <SEO 
            title="Contact Mike | Mike Martin Law"
            description="Need help with a contested probate case or have questions about probate administration? Contact Mike to get the legal expertise you need."
        />
        <Card>
            <div className="flex flex-col sm:flex-row gap-x-10 w-full items-center border-b border-gray-200 pb-8">
                <img 
                    src={mike}
                    alt="Attorney Mike Martin headshot"
                    className="col-span-4 w-96"
                />
                <div>
                    <h1 className="col-span-8 text-4xl font-bold mb-4">Contact Mike</h1>
                    <div className="flex gap-x-2">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg> */}
                        <p>4800 Rainbow Blvd., Suite 100<br />Westwood, Kansas 66205-1932</p>
                    </div>
                    <div className="flex items-center mb-4 gap-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                        </svg>
                        <a href="tel:9133628560" className="text-lg">(913) 362-8560</a>
                    </div>
                    <div className="flex items-center mb-4 gap-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                        </svg>
                        <span className="text-lg">(913) 362-5480</span>
                    </div>
                    <div className="flex items-center mb-4 gap-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                        <a href="mailto:info@mikemartinlaw.com" className="text-lg">info@mikemartinlaw.com</a>
                    </div>
                </div>
            </div>
        </Card>
    </Layout>
  )
}

export default Estate;